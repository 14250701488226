@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-content {
  height: 20vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h1 {
  padding: 0;
  margin-bottom:0;
}

p{
  padding: 0;
  margin:0;
}

.App-playstore{
  margin: 10px;
}

.App-playstore img{
  width: 10em;
}

.alert{
  margin-top: 20px
}

